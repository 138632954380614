export enum ISortDirection {
  ASC,
  DESC,
}

export class BaseModel {
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export interface ElapsedTime {
  hours: number;
  minutes: number;
  seconds: number;
}

export class TimeStampedBaseModel extends BaseModel {
  createdAt: string;
  updatedAt: string;

  static sortByUpdatedAt<T extends TimeStampedBaseModel>(arr: T[], direction: ISortDirection): T[] {
    if (direction === ISortDirection.ASC) {
      return arr.sort((l, r) => new Date(l.updatedAt).getTime() - new Date(r.updatedAt).getTime());
    } else {
      return arr.sort((l, r) => new Date(r.updatedAt).getTime() - new Date(l.updatedAt).getTime());
    }
  }

  getHumanizedUpdatedAt() {
    return `${this.formatTimestampToLocalizedDateString(this.updatedAt)} - ${this.formatTimestampToLocalizedTimeString(
      this.updatedAt
    )}`;
  }

  getCreatedAtDate() {
    return this.formatTimestampToLocalizedDateString(this.createdAt);
  }

  getLastUpdatedAtDate(): string {
    return this.formatTimestampToLocalizedDateString(this.updatedAt);
  }

  getElapsedTimeBetweenCreateAndUpdate(): ElapsedTime {
    let duration = new Date(this.createdAt).getTime() - new Date(this.updatedAt).getTime() / 1000;
    const days = Math.floor(duration / 86400);

    duration -= days * 86400;

    const hours = Math.floor(duration / 3600) % 24;

    duration -= hours * 3600;

    const minutes = Math.floor(duration / 60) % 60;

    duration -= minutes * 60;

    const seconds = Math.floor(duration);

    return {
      hours: hours > 0 ? hours : 0,
      minutes: minutes > 0 ? minutes : 0,
      seconds: seconds > 0 ? seconds : 0,
    };
  }

  formatTimestampToLocalizedDateString(date: string): string {
    return new Date(`${date}Z`).toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  formatTimestampToLocalizedTimeString(date: string): string {
    return new Date(`${date}Z`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
  }
}
