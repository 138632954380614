import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import singleton from '@helpers/singleton';

import { LoaderService } from './loaderService';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  static temporarilyDisable(ms: number = 5) {
    interceptorConfig.getInstance().interceptorActive = false;
    setTimeout(() => {
      interceptorConfig.getInstance().interceptorActive = true;
    }, ms);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const interceptorActive = interceptorConfig.getInstance().interceptorActive;

    if (interceptorActive) {
      this.showLoader();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (interceptorActive) {
              this.onEnd();
            }
          }
        },
        () => {
          if (interceptorActive) {
            this.onEnd();
          }
        }
      )
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}

const _interceptorConfig = {
  interceptorActive: true,
};

export const interceptorConfig = singleton(() => _interceptorConfig);
