import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  @ViewChild('sidenav', {
    static: true,
  })
  sidenav;

  @Output() accountIconClicked = new EventEmitter();

  constructor() {}
}
