import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent {
  @Input() header: string;
  @Input() label: string;
  @Input() alt = 'empty';

  constructor() {}
}
