import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {GoodsReceipt, GoodsReceiptStatus} from '@models/goodsReceipt';


@Injectable({
  providedIn: 'root',
})
export class GoodsreceiptPermission {
  constructor(private router: Router) {}

  canStartReceive(goodsReceipt: GoodsReceipt): boolean {
    if (goodsReceipt.isTodo && !goodsReceipt.isFreeReceipt) {
      return true;
    } else {
      debugger;
      this.navigateAway();

      return false;
    }
  }

  canReceive(goodsReceipt: GoodsReceipt): boolean {
    if (goodsReceipt.status === GoodsReceiptStatus.RECEIVING) {
      return true;
    } else {
      this.navigateAway();

      return false;
    }
  }

  canViewComplete(goodsReceipt: GoodsReceipt): boolean {
    const allowedStatuses = [
      GoodsReceiptStatus.RECEIVED,
      GoodsReceiptStatus.SYNCED,
      GoodsReceiptStatus.PUTTINGAWAY,
      GoodsReceiptStatus.SYNCED,
      GoodsReceiptStatus.DONEPUTTINGAWAY
    ];

    if (allowedStatuses.indexOf(goodsReceipt?.status) > -1) {
      return true;
    } else {
      this.navigateAway();

      return false;
    }
  }

  navigateAway() {
    this.router.navigate(['/receive/forbidden'], { skipLocationChange: true });
  }
}
