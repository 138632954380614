import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@helpers/helper';
import { Item } from '@models/item';
import { UnitOfMeasurementUpdateModel } from '@models/unitOfMeasure';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  constructor(private http: HttpClient) {}

  getItemByName(name): Observable<Item[]> {
    return this.http.get<Item[]>(`${environment.serviceUrl}/item?name=${name}`, httpOptions);
  }

  getItemByBarcode(barcode): Observable<Item> {
    return this.http.get<Item>(`${environment.serviceUrl}/item?barcode=${barcode}`, httpOptions);
  }

  searchItemByName(name): Observable<Item[]> {
    return this.http.get<Item[]>(`${environment.serviceUrl}/item/search?name=${name}`, httpOptions);
  }

  printAlternateBarcode(alternateBarcode, storeId): Observable<any> {
    return this.http.post<Item>(
      `${environment.serviceUrl}/item/${alternateBarcode.itemId}/alternateBarcode` + `/${storeId}`,
      alternateBarcode,
      httpOptions
    );
  }

  updateAlternateBarcode(alternateBarcode, id, storeId): Observable<any> {
    return this.http.put<Item>(
      `${environment.serviceUrl}/item/${alternateBarcode.itemId}/alternateBarcode/${id}/${storeId}`,
      alternateBarcode,
      httpOptions
    );
  }

  updateUoM(uomModel: UnitOfMeasurementUpdateModel): Observable<any> {
    return this.http.put<Item>(`${environment.serviceUrl}/item/uom`, uomModel, httpOptions);
  }
}
