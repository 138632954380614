import { MaterialModule } from 'src/app/material design/material.module';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AddItemCountComponent } from '@components/add-item-count/add-item-count.component';
import { SharedComponentsModule } from '@components/sharedComponent.module';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { CancelDialogComponent } from '@dialogs/cancel-dialog/cancel-dialog.component';
import { ConfirmReceiptDialogComponent } from '@dialogs/confirm-receipt-dialog/confirm-receipt-dialog.component';
import { DeleteLineDialogComponent } from '@dialogs/delete-line-dialog/delete-line-dialog.component';
import { ErrorListDialogComponent } from '@dialogs/error-list-dialog/error-list-dialog.component';
import { ExpirationDialogComponent } from '@dialogs/expiration-dialog/expiration-dialog.component';
import { GoodsReceiptDeleteDialogComponent } from '@dialogs/goods-receipt-delete-dialog/goods-receipt-delete-dialog.component';
import { ItemNotFoundDialogComponent } from '@dialogs/item-not-found-dialog/item-not-found-dialog.component';
import { NotInPicklistDialogComponent } from '@dialogs/not-in-picklist-dialog/not-in-picklist-dialog.component';
import { UomDialogComponent } from '@dialogs/uom-dialog/uom-dialog.component';
import { WarehouseDialogComponent } from '@dialogs/warehouse-dialog/warehouse-dialog.component';
import { AutofocusDirective } from '@directives/AutofocusDirective';
import { MatTabWithParamsDirective } from '@directives/mat-tab-with-params.directive';
import { SearchItemComponent } from '@modules/search-item/search-item/search-item.component';
import { LoginComponent } from '@modules/security/login/login.component';
import { LineConfirmationViewComponent } from '@modules/shared/line-confirmation-view/line-confirmation-view.component';
import * as Sentry from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { AuthGuardService } from '@services/auth/auth-guard.service';
import { AuthInterceptor } from '@services/auth/auth-interceptor';
import { AuthService } from '@services/auth/auth.service';
import { CommunicationService } from '@services/communicationService';
import { LoaderInterceptorService } from '@services/loader/loaderInterceptorService';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.production ? 'production' : 'test',
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [environment.serviceUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
        idleTimeout: 16000,
      }),
    ],
    initialScope: (scope) => {
      scope.setLevel(Sentry.Severity.Warning);

      return scope;
    },
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    debug: false,
    release: environment.version,
  });
}

const dialogs = [
  WarehouseDialogComponent,
  UomDialogComponent,
  CancelDialogComponent,
  NotInPicklistDialogComponent,
  ErrorListDialogComponent,
  ItemNotFoundDialogComponent,
  GoodsReceiptDeleteDialogComponent,
  DeleteLineDialogComponent,
  ConfirmReceiptDialogComponent,
  ExpirationDialogComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    AutofocusDirective,
    LoginComponent,
    SpinnerComponent,
    AddItemCountComponent,
    LineConfirmationViewComponent,
    MatTabWithParamsDirective,
    SearchItemComponent,
    ...dialogs,
  ],
  exports: [],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    SharedComponentsModule,
    MatBottomSheetModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthGuardService,
    AuthService,
    CommunicationService,
    // Start Sentry shizzle
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    // End Sentry shizzle
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [AddItemCountComponent, ...dialogs],
})
export class AppModule {}
