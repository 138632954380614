import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-receipt-dialog',
  templateUrl: './confirm-receipt-dialog.component.html',
  styleUrls: ['./confirm-receipt-dialog.component.scss'],
})
export class ConfirmReceiptDialogComponent implements OnInit {
  title: string;

  constructor(private dialog: MatDialogRef<ConfirmReceiptDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.title = this.data.name;
  }

  save() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }
}
