import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';

type Options = { icon: string; text: string; action?: string }[];
type MenuData = {
  header: string;
  options: Options;
};

@Component({
  selector: 'app-more-menu-bottomsheet',
  templateUrl: './more-menu-bottomsheet.component.html',
  styleUrls: ['./more-menu-bottomsheet.component.scss'],
})
export class MoreMenuBottomsheetComponent implements OnInit {
  header: string;
  options: Options;

  constructor(public bottomSheet: MatBottomSheet, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

  ngOnInit(): void {
    const data = this.data as MenuData;

    this.header = data.header;
    this.options = data.options;
  }

  close() {
    this.bottomSheet.dismiss();
  }

  clickOption(index: number) {
    this.bottomSheet.dismiss(this.options[index].action);
  }
}
