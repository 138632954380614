<mat-dialog-content #templateBottomSheet>
  <h2 *ngIf="header">{{ header }}</h2>

  <mat-selection-list [multiple]="false">
    <ng-container *ngFor="let option of options; let i = index">
      <mat-list-option (click)="clickOption(i)">
        <mat-icon mat-list-icon>{{ option.icon }}</mat-icon>
        <div mat-line>{{ option.text }}</div>
      </mat-list-option>
    </ng-container>
    <mat-list-item class="button-item">
      <button class="button_green" (click)="close()" mat-button>CANCEL</button>
    </mat-list-item>
  </mat-selection-list>
</mat-dialog-content>
