import { BaseModel } from './basemodel';

export class Location extends BaseModel {
  id: number;
  name: string;
  barcode: string;
  locationType: LocationType;
  status: LocationStatus;

  constructor(data?: any) {
    super(data);

    this.locationType = new LocationType(data?.locationType);
  }

  getStatusIcon(): string {
    switch (this.status) {
      case LocationStatus.AVAILABLE:
        return 'radio_button_unchecked';
      case LocationStatus.INPROGRESS:
        return 'radio_button_checked';
      case LocationStatus.PUTTINGAWAY:
        return 'downloading';
    }
  }

  getStatusClass() {
    switch (this.status) {
      case LocationStatus.AVAILABLE:
        return 'available';
      case LocationStatus.INPROGRESS:
        return 'selected';
      case LocationStatus.PUTTINGAWAY:
        return 'occupied';
    }
  }

  isInUse(): boolean {
    return this.status != LocationStatus.AVAILABLE;
  }
}

export class LocationType extends BaseModel {
  id: number;
  name: LocationTypeName;
}

export enum LocationTypeName {
  RECEIVING = 'receiving_lane',
  BIN = 'bin',
  TAG = 'tag',
}

export enum LocationStatus {
  AVAILABLE = 'available',
  INPROGRESS = 'in_progress',
  READYFORPUTAWAY = 'ready_for_put_away',
  PUTTINGAWAY = 'putting_away',
}
