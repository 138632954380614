import { BaseModel } from './basemodel';
import { Item } from './item';

export class UnitOfMeasurement extends BaseModel {
  id: number;
  name: string;
  quantity: number;
  unitCost: number;
  default: boolean;
  barcode: string;
  cost: number;
  itemId: number;
  item: Item;
}

export class UnitOfMeasurementUpdateModel extends BaseModel {
  id: number;
  itemId: number;
  barcode: string;
}
