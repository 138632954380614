import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Login } from '@models/login';
import { User } from '@models/user';
import { AuthService } from '@services/auth/auth.service';
import { LoginService } from '@services/auth/loginService';
import { StoreService } from '@services/storeService';
import { UserService } from '@services/userService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  error: string;
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  showPassword = false;

  showSpinner = false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']).then(() => window.location.reload());
    }
  }

  submit() {
    if (this.form.valid) {
      this.showSpinner = true;

      this.form.get('email').disable();
      this.form.get('password').disable();

      this.loginService.login(this.form.controls.email.value, this.form.controls.password.value).subscribe(
        (login: Login) => {
          localStorage.setItem('WMS_sessionToken', login.token);
          localStorage.setItem('WMS_sessionExpire', login.expiration);
          this.storeService.storeSelectedStore(login.defaultStore);

          this.userService.getUser().subscribe((user: User) => {
            localStorage.setItem('WMS_Username', user.emailAddress);

            this.router.navigate(['/dashboard']);

            this.showSpinner = false;
            this.form.get('email').enable();
            this.form.get('password').enable();
          });
        },
        (error) => {
          this.error = 'Username or password incorrect';
          console.log(`something went wrong${error}`);

          this.showSpinner = false;
          this.form.get('email').enable();
          this.form.get('password').enable();
        }
      );
    } else {
      if (this.form.controls.email.errors.email) {
        this.error = 'Username is not a valid email';
      } else {
        this.error = 'Username and password are required';
      }
    }
  }
}
