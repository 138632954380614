import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    this.cdRef.detectChanges();
  }
}
