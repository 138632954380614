<mat-dialog-content>
  <label>Change expiration date?</label>
</mat-dialog-content>
<mat-form-field class="example-full-width" style="margin-top: 25px">
  <mat-label>Choose a date</mat-label>
  <input matInput [matDatepicker]="picker" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker touchUi #picker></mat-datepicker>
</mat-form-field>
<mat-dialog-actions style="margin-top: 50px">
  <div style="display: block; width: 100%">
    <button class="mat-raised-button ok-button" (click)="save()">Ok</button>
    <button class="mat-raised-button cancel-button mat-primary" (click)="close()">Cancel</button>
  </div>
</mat-dialog-actions>
