import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  /**
   * url of the image to display
   */
  @Input() imageUrl?: string;
  /**
   * Name of object described by tha avatar
   */
  @Input() name?: string;

  initials: string;
  showImage = true;

  constructor() {}

  ngOnInit(): void {
    if (this.name && this.name?.length) {
      this.initials = this.name
        .replace(/[^a-z\s]/gis, '')
        .split(' ')
        .reduce((prev, curr) => prev + curr.charAt(0).toUpperCase(), '')
        .slice(0, 3);
    }
  }

  onImageLoadError() {
    this.showImage = false;
  }

  getBackgroundColor(): string {
    return this.initials?.length ? `background-${((this.initials.charCodeAt(0) - 65) % 19) + 1}` : 'background-1';
  }
}
