import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  login(UserName, Password) {
    return this.http.post(`${environment.serviceUrl}/security/login`, { UserName, Password }, httpOptions);
  }

  logout() {
    localStorage.removeItem('WMS_sessionToken');
    localStorage.removeItem('WMS_Store');
    localStorage.removeItem('WMS_Username');
  }
}
