import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@modules/security/login/login.component';
import { ItemPickerComponent } from '@modules/shared/item-picker/item-picker.component';
import { LineConfirmationViewComponent } from '@modules/shared/line-confirmation-view/line-confirmation-view.component';
import { AuthGuardService } from '@services/auth/auth-guard.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'receive',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/receiving/receiving.module').then((m) => m.ReceivingModule),
  },
  {
    path: 'putaway',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/putaway/putaway.module').then((m) => m.PutawayModule),
  },
  {
    path: 'picking',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/picking/picking.module').then((m) => m.PickingModule),
  },
  {
    path: 'count',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/count/count.module').then((m) => m.CountModule),
  },
  {
    path: 'waste',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/waste/waste.module').then((m) => m.WasteModule),
  },
  {
    path: 'shortDate',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/short-date/short-date.module').then((m) => m.ShortDateModule),
  },
  {
    path: 'lookup',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/lookup/lookup.module').then((m) => m.LookupModule),
  },
  {
    path: 'searchItem',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@modules/search-item/search-item.module').then((m) => m.SearchItemModule),
  },
  // Item picker routes
  {
    path: 'itemPicker/:id/:itemId/:uomId',
    canActivate: [AuthGuardService],
    component: ItemPickerComponent,
  },
  {
    path: 'itemPicker',
    canActivate: [AuthGuardService],
    component: ItemPickerComponent,
  },

  // confirmation route
  {
    path: 'lineConfirmation',
    canActivate: [AuthGuardService],
    component: LineConfirmationViewComponent,
  },

  // Catch all route
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
