import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-not-in-picklist-dialog',
  templateUrl: './not-in-picklist-dialog.component.html',
  styleUrls: ['./not-in-picklist-dialog.component.scss'],
})
export class NotInPicklistDialogComponent {
  constructor(private dialog: MatDialogRef<NotInPicklistDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  save() {
    this.dialog.close(true);
  }
}
