<app-nav-tool-bar-component
  iconType="arrow_forward"
  [navigationTitle]="title"
  [navigationSubtitle]="subtitle"
  navigation="/receive"
  [backNavigationQueryParams]="{ tab: '1' }"
  (valueChange)="closeReceipt()"
  [showMoreButton]="true"
  (showMore)="showMoreMenu()"
  [hideNextButton]="true">
</app-nav-tool-bar-component>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="goodsReceipt.isFreeReceipt">
    <app-search-input
      #barcode
      [hideVirtualKeyboard]="true"
      (iconPress)="searchItem()"
      inputTitle="Scan a barcode"
      (submitSearch)="barcodeScanned($event)"
      icon="search">
    </app-search-input>

    <app-empty-list
      header="This goods receipt is empty"
      label="Please scan or search an item"
      *ngIf="goodsReceipt.goodsReceiptLines.length === 0 && !isLoading"></app-empty-list>

    <div *ngIf="goodsReceipt.goodsReceiptLines.length > 0">
      <ng-container *ngFor="let group of linesDoneGroupedByLocation">
        <app-lines-group
          [group]="group"
          (discardItems)="discardItem($event)"
          (openLine)="openItem($event)"
          [isInUse]="group.location.id !== goodsReceipt.locationId">
          ></app-lines-group
        >
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!goodsReceipt.isFreeReceipt">
    <mat-tab-group
      mat-stretch-tabs
      class="tab-group-fixed"
      #matTabGroup
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="tabChanged($event)">
      <mat-tab label="{{ linesToDoCount }}  TODO" style="height: 100%">
        <app-search-input
          #barcodeInput1
          [hideVirtualKeyboard]="true"
          #searchInput
          inputTitle="Search"
          (submitSearch)="searchSubmitted($event)">
        </app-search-input>

        <app-empty-list
          header="Nothing left to do!"
          label="You can submit this Goods Receipt"
          *ngIf="linesToDoCount === 0">
        </app-empty-list>

        <mat-list *ngIf="linesToDoCount > 0">
          <app-item-quantity-list
            [line]="line"
            [onlyAmount]="false"
            (openLine)="updateItem($event)"
            *ngFor="let line of linesToDo">
          </app-item-quantity-list>
        </mat-list>
      </mat-tab>

      <mat-tab label="{{ linesDoneCount }} DONE">
        <div *ngIf="linesDoneCount > 0">
          <app-search-input
            #barcodeInput2
            [hideVirtualKeyboard]="true"
            inputTitle="Search"
            (submitSearch)="searchSubmitted($event)">
          </app-search-input>
        </div>

        <ng-container *ngFor="let group of linesDoneGroupedByLocation">
          <app-lines-group
            [group]="group"
            (openLine)="updateItem($event)"
            [linesAreAmountOnly]="false"
            [linesAreDiscardable]="false"
            [isInUse]="group.location.id !== goodsReceipt.locationId"></app-lines-group>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <app-sticky-container position="bottom" *ngIf="goodsReceipt?.goodsReceiptLines?.length">
    <button class="button_green" (click)="closeReceipt()" mat-button>FINISH</button>
  </app-sticky-container>
</ng-container>

<ng-container *ngIf="isLoading">
  <mat-list>
    <app-repeat times="6">
      <app-item-quantity-list [skeleton]="true"></app-item-quantity-list>
    </app-repeat>
  </mat-list>
</ng-container>
