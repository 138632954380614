import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CancelDialogComponent, ICancelDialogProps } from '@dialogs/cancel-dialog/cancel-dialog.component';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-nav-tool-bar-component',
  templateUrl: './nav-tool-bar-component.component.html',
  styleUrls: ['./nav-tool-bar-component.component.scss'],
})
export class NavToolBarComponentComponent {
  /**
   * The URL to navigate to when the user presses on the next button.
   */
  @Input() navigation: string;
  /**
   * The title of the head element
   */
  @Input() navigationTitle: string;
  /**
   * The sub-title of the head element. will be displayed below the title
   */
  @Input() navigationSubtitle: string;
  /**
   * Attaches query parameters when navigating backwards.
   * NOTE: Will only work if ``` disableAutoBack ``` is set to ``` false ```
   */
  @Input() backNavigationQueryParams: Record<string, string>;
  @Input() navigationState: any;
  @Input() hideNextButton: boolean;
  @Input() iconType: string;
  /**
   * Indicates if a confirmation screen should be presented to the user before going backwards.
   */
  @Input() confirmOnBack = false;
  /**
   * What is displayed in the confirmation modal that is displayed if the ``` confirmOnBack ``` input is set to true.
   */
  @Input() backConfirmationTitle = 'Are you sure you want to go back';
  @Input() confirmationDialogConfig: ICancelDialogProps;
  /**
   * Is triggered when the user presses the next button, or if a confirmation screen is present it is triggered when
   * the user confirms this.
   */
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() cancelDialog: EventEmitter<any> = new EventEmitter();
  /**
   * Disables traveling backwards when no location (navigation) is supplied.
   * To be used with backButtonPressed if instead of going back we want to trigger a different event
   * @see this.navigation
   */
  @Input() disableAutoBack = false;
  /**
   * Fires when the user presses the back button.
   * Can be used with disableAutoBack to override the navigation behavior of this component.
   * > Note: this fires (is triggered) ***BEFORE*** the navigation takes place,
   * thus making it suitable to do blocking (non async) "stuff" before the navigation takes place
   */
  @Output() backButtonPressed: EventEmitter<any> = new EventEmitter();
  @Input() needsConfirm: boolean;

  @Input() showMoreButton: false;
  @Output() showMore: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private dialogService: DialogService, private location: Location) {}

  /**
   * Waits for a modal. when interaction is done it resolves to:
   * - true ::: when the user confirms.
   * - false ::: when the user cancels or does not confirm.
   */
  waitForModal(): Promise<boolean> {
    return new Promise((res) => {
      const dialog = this.dialogService.open(CancelDialogComponent, {
        name: this.backConfirmationTitle,
        ...this.confirmationDialogConfig,
      } as ICancelDialogProps);

      dialog.afterClosed().subscribe((data) => {
        res(!!data);
      });
    });
  }

  navigateBack() {
    this.backButtonPressed.emit();

    const tryNav = () => {
      if (!this.navigation && !this.disableAutoBack) {
        this.location.back();
      } else {
        this.router.navigate([`/${this.navigation}`], {
          queryParams: this.backNavigationQueryParams,
          state: this.navigationState ? this.navigationState : {},
        });
      }
    };

    if (this.confirmOnBack) {
      this.waitForModal().then((confirmed) => {
        if (confirmed) {
          tryNav();
        }
      });
    } else {
      tryNav();
    }
  }

  next() {
    if (this.needsConfirm) {
      const dialog = this.dialogService.open(CancelDialogComponent, {
        name: 'Confirm changes',
      });

      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.valueChange.emit();
        } else {
          this.cancelDialog.emit();
        }
      });
    } else {
      this.valueChange.emit();
    }
  }

  more() {
    this.showMore.emit();
  }
}
