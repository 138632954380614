import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorListDialogComponent } from '@dialogs/error-list-dialog/error-list-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {
    this.config.disableClose = true;
    this.config.autoFocus = true;
  }

  open<T, R = any>(component: ComponentType<T>, data?: object): MatDialogRef<T, R> {
    return this.dialog.open(component, Object.assign(this.config, { data }));
  }

  openErrorDialog(errors: string[]) {
    return this.dialog.open(ErrorListDialogComponent, Object.assign(this.config, { data: { errors } }));
  }
}
