import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitOfMeasurementUpdateModel } from '@models/unitOfMeasure';
import { KEY_CODE } from '@modules/receiving/receive-items/receive-items.component';
import { ItemService } from '@services/itemService';

@Component({
  selector: 'app-warehouse-dialog',
  templateUrl: './warehouse-dialog.component.html',
  styleUrls: ['./warehouse-dialog.component.scss'],
})
export class WarehouseDialogComponent {
  @ViewChild('barcode') barcodeInput: ElementRef;
  scannedData: string;
  uomUpdateModel: UnitOfMeasurementUpdateModel = new UnitOfMeasurementUpdateModel();

  constructor(
    private dialog: MatDialogRef<WarehouseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: ItemService
  ) {}

  @HostListener('click', ['$event']) onClick(e) {
    this.barcodeInput.nativeElement.focus();

    if (e.target.className === 'mat-dialog-content') {
      this.barcodeInput.nativeElement.focus();
    }
  }

  // Capture scan and send boxcode to API for UoM
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: any) {
    if (event.keyCode === KEY_CODE.TAB || event.keyCode === KEY_CODE.ENTER) {
      this.uomUpdateModel.barcode = this.scannedData;
      this.uomUpdateModel.id = this.data.chosenUom.id;
      this.uomUpdateModel.itemId = this.data.itemId;

      this.itemService.updateUoM(this.uomUpdateModel).subscribe(
        () => {
          this.dialog.close(this.scannedData);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  save() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }
}
