<h1 mat-dialog-title>Choose unit</h1>
<mat-dialog-content>
  <mat-radio-group
    style="text-align: center"
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="chosenUOM">
    <mat-radio-button style="margin-top: 24px" class="example-radio-button" *ngFor="let uom of data.uom" [value]="uom">
      {{ uom.name }} - {{ uom.quantity }} Pcs.
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="mat-button" (click)="close()">Cancel</button>
  <button style="background-color: #00b188" class="mat-raised-button" (click)="save()">Ok</button>
</mat-dialog-actions>
