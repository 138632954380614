import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('No-Auth') === 'True') {
      return next.handle(req.clone());
    } else {
      if (localStorage.getItem('WMS_sessionToken') != null) {
        const clonedreq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('WMS_sessionToken')}`),
        });

        return next.handle(clonedreq);
      } else {
        return next.handle(req.clone());
      }
    }
  }
}
