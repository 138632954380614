import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title?: string;
  errors: Record<string, string[]>;
}

@Component({
  selector: 'app-error-list-dialog',
  templateUrl: './error-list-dialog.component.html',
  styleUrls: ['./error-list-dialog.component.scss'],
})
export class ErrorListDialogComponent {
  errors: string[];

  constructor(public dialog: MatDialogRef<ErrorListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.errors = Object.values(data.errors).flatMap((e) => e);
  }

  dismissPressed() {
    this.dialog.close();
  }
}
