import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GoodsReceiptLine } from '@models/goodsReceipt';
import { PickListLine } from '@models/picklist';

@Component({
  selector: 'app-item-quantity-list',
  templateUrl: './item-quantity-list.component.html',
  styleUrls: ['./item-quantity-list.component.scss'],
})
export class ItemQuantityListComponent {
  @Input() line: PickListLine | GoodsReceiptLine;
  @Input() type: string;
  @Input() dotColor: string;
  @Input() onlyAmount: boolean;
  @Input() skeleton: false;
  @Input() isDiscardable: false;
  @Input() isDisabled: false;

  @Output() discardLine = new EventEmitter();
  @Output() openLine = new EventEmitter();

  constructor() {}

  quantityPicked(): number {
    if (this.line instanceof GoodsReceiptLine) {
      return this.line.receivedQuantity ? this.line.receivedQuantity : 0;
    } else {
      return this.line.quantityPicked ? this.line.quantityPicked : 0;
    }
  }

  open(event: MouseEvent): void {
    event.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    this.openLine.emit(this.line);
  }

  discard(event: MouseEvent): void {
    event.stopPropagation();

    this.discardLine.emit(this.line);
  }
}
