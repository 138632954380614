<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button class="menu-button" (click)="navigateBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="menu-header">
      <span class="main-header {{ !navigationTitle ? 'placeholder placeholder-md' : '' }}">{{ navigationTitle }}</span>
      <span class="sub-header" *ngIf="navigationSubtitle">{{ navigationSubtitle }}</span>
    </div>
    <span class="toolbar-spacer"></span>
    <div matRipple *ngIf="showMoreButton" class="box" (click)="more()">
      <mat-icon class="box-icon">more_vert</mat-icon>
    </div>
    <div matRipple *ngIf="!hideNextButton" class="box box_green" (click)="next()">
      <mat-icon class="box-icon">{{ iconType }}</mat-icon>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
