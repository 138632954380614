<h1 mat-dialog-title>{{ title }}?</h1>
<mat-dialog-actions align="end">
  <button class="mat-button" [ngClass]="['mat-button', 'button_cancel']" (click)="close()">
    {{ data?.cancelLabel || 'Cancel' }}
  </button>
  <button
    [ngStyle]="{ 'background-color': data?.confirmColor || '#00b188' }"
    [ngClass]="{
      'mat-raised-button': true,
      button_default: true,
      button_warning: data?.isWarning
    }"
    (click)="save()">
    {{ data?.confirmLabel || 'Confirm' }}
  </button>
</mat-dialog-actions>
