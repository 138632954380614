import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmReceiptDialogComponent } from '@dialogs/confirm-receipt-dialog/confirm-receipt-dialog.component';
import singleton from '@helpers/singleton';
import { GoodsReceiptLine } from '@models/goodsReceipt';
import { Picklist, PickListLine } from '@models/picklist';
import { CommunicationService } from '@services/communicationService';
import { DialogService } from '@services/dialog.service';
import { LoaderService } from '@services/loader/loaderService';

type lineTypes = GoodsReceiptLine | PickListLine;
export interface LineConfirmationState {
  linesDone?: lineTypes[];
  linesToDo?: lineTypes[];
  linesOpen?: lineTypes[];
  linesPicked?: lineTypes[];

  linesDoneTitle?: string;
  linesToDoTitle?: string;
  linesOpenTitle?: string;
  linesPickedTitle?: string;

  backNavLink?: string;
  confirmNav?: any[];
  showConfirmDialog?: boolean;
  dialogConfig?: {
    text: string;
  };
}

const _lineConfirmationState: LineConfirmationState = {
  backNavLink: '..',
};
const _confirmationRequest = {
  triggered: () => {
    console.log('confirmation request triggered');
  },
};

/**
 * Exposes a singleton containing an object with a function pointer. this pointer shall be called
 * when the ```LineConfirmationViewComponent``` component is confirmed (user presses green confirm button).
 * To change the behavior after this confirm button click one could use the setter to manipulate it, putting a new function in it's place
 * @example
 * confirmationRequest.getInstance().triggered = () => {
 *    this.updateReceipt = new UpdateReceipt();
 *    this.updateReceipt.status = 2;
 *    this.updateReceipt.id = this.goodsReceipt.id;
 *    this.goodsReceiptService
 *      .closeGoodsReceipt(this.updateReceipt)
 *      .subscribe((res) => {
 *        this.router.navigate(['/receiptCommitted/' + this.goodsReceipt.id]);
 *      });
 *  };
 */
export const confirmationRequest = singleton(() => _confirmationRequest);
export const lineConfirmationState = singleton(() => _lineConfirmationState);
/**
 * A view to be used when one needs to confirm with the user on certain line items before continuing with other logic.
 * To use this component you need to navigate to it with special state, below you can find an example.
 * To view all configuration possibilities check the interface ```LineConfirmationState```
 * @see {LineConfirmationState}
 * @example
 * this.router.navigate(['/lineConfirmation'], {
 *     state: {
 *      linesOpen: this.goodsReceipt.goodsReceiptLines,
 *      backNavLink: this.router.url,
 *    },
 *  });
 *  confirmationRequest.getInstance().triggered = () => {
 *    this.updateReceipt = new UpdateReceipt();
 *    this.updateReceipt.status = 2;
 *    this.updateReceipt.id = this.goodsReceipt.id;
 *    this.goodsReceiptService
 *      .closeGoodsReceipt(this.updateReceipt)
 *      .subscribe((res) => {
 *        this.router.navigate(['/receiptCommitted/' + this.goodsReceipt.id]);
 *      });
 *  };
 */
@Component({
  selector: 'app-line-confirmation-view',
  templateUrl: './line-confirmation-view.component.html',
  styleUrls: ['./line-confirmation-view.component.scss'],
})
export class LineConfirmationViewComponent implements OnInit {
  navigationTitle: string;
  navigation: string;
  iconType: string;
  line: Picklist;
  dotcolor: string;
  linesToDo = [];
  linesDone = [];
  linesOpen = [];
  linesPicked = [];
  navState: LineConfirmationState;

  isLoading = false;

  constructor(
    private communicationService: CommunicationService,
    private dialogService: DialogService,
    private router: Router,
    private location: Location,
    private loaderService: LoaderService
  ) {
    this.navState = lineConfirmationState.getInstance();
  }

  ngOnInit() {
    this.communicationService.emitChange();
    this.navigationTitle = 'Confirmation';

    this.loaderService.loaderState.subscribe((x) => {
      this.isLoading = x.show;
    });

    this.iconType = 'done';

    if (!this.navState) {
      this.location.back();

      return;
    }

    this.navigation = this.navState.backNavLink;
    this.linesDone = this.navState.linesDone || [];
    this.linesToDo = this.navState.linesToDo || [];
    this.linesOpen = this.navState.linesOpen || [];
    this.linesPicked = this.navState.linesPicked || [];
  }

  static setState(st: LineConfirmationState) {
    lineConfirmationState.setInstance(st);
  }

  private finalize() {
    confirmationRequest.getInstance().triggered();

    if (this.navState.confirmNav) {
      this.router.navigate(this.navState.confirmNav);
    }
  }

  nextClick() {
    if (this.navState.showConfirmDialog) {
      const dialog = this.dialogService.open(ConfirmReceiptDialogComponent, {
        name: this.navState.dialogConfig?.text || 'request completed',
      });

      dialog.afterClosed().subscribe(async (data) => {
        if (data) {
          this.finalize();
        }
      });
    } else {
      this.finalize();
    }
  }
}
