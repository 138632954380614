import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  constructor() {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('WMS_sessionToken');
    const sessionExpire = new Date(localStorage.getItem('WMS_sessionExpire'));

    if (sessionExpire < new Date()) {
      return false;
    }

    // Check whether the token is valid or expired and return
    // true or false
    return token !== null;
  }
}
