<mat-toolbar style="background: #242d35">
  <button mat-icon-button class="menu-button">
    <mat-icon>apps</mat-icon>
  </button>
  <span style="padding-left: 24px">WMS</span>
  <span class="toolbar-spacer"></span>
  <button routerLink="/lookup" mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <button mat-icon-button (click)="accountIconClicked.emit()" data-test="account-button">
    <mat-icon class="material-icons-outlined">account_circle</mat-icon>
  </button>
</mat-toolbar>
