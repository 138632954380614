import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

export enum StickyPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
}

@Component({
  selector: 'app-sticky-container',
  templateUrl: './sticky-container.component.html',
  styleUrls: ['./sticky-container.component.scss'],
})
export class StickyContainerComponent implements AfterViewInit, OnDestroy {
  @Input() position: StickyPosition;
  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  private rootRef: HTMLElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.rootRef = this.el.nativeElement.closest('app-root');
    if (this.rootRef) this.rootRef.style.paddingBottom = `${this.container.nativeElement.offsetHeight + 20}px`;
  }

  ngOnDestroy() {
    if (this.rootRef) this.rootRef.style.removeProperty('padding-bottom');
  }
}
