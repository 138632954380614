<mat-dialog-content #templateBottomSheet>
  <h2>Locations</h2>

  <mat-selection-list [multiple]="false">
    <h3 matSubheader>SELECT</h3>
    <ng-container *ngFor="let location of goodsReceipt.usedLocations; let i = index">
      <mat-list-option
        (click)="selectLocation(location)"
        [ngClass]="[location.getStatusClass(), isCurrentLocation(location) ? 'current' : '']"
        [disabled]="location.isInUse()">
        <mat-icon matListIcon>{{ location.getStatusIcon() }}</mat-icon>
        <div mat-line>
          {{ location.name }}
          <mat-icon
            matListIcon
            #tooltip="matTooltip"
            [matTooltip]="
              'This location is in use for ' + (location.status === 'in_progress' ? 'receiving' : 'put away') + '.'
            "
            matTooltipPosition="left"
            matTooltipHideDelay="1000"
            *ngIf="location.isInUse() && !isCurrentLocation(location)">
            info
          </mat-icon>
        </div>
      </mat-list-option>
    </ng-container>
    <h3 matSubheader>OPTIONS</h3>
    <mat-list-option (click)="addLocation()">
      <mat-icon mat-list-icon>add</mat-icon>
      <div mat-line>Add location</div>
    </mat-list-option>
    <mat-list-item class="button-item">
      <button class="button_green" (click)="close()" mat-button>CANCEL</button>
    </mat-list-item>
  </mat-selection-list>
</mat-dialog-content>
