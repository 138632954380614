import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expiration-dialog',
  templateUrl: './expiration-dialog.component.html',
  styleUrls: ['./expiration-dialog.component.scss'],
})
export class ExpirationDialogComponent implements OnInit {
  expirationDate: Date;

  constructor(private dialog: MatDialogRef<ExpirationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.expirationDate = this.data.expirationDate;
  }

  save() {
    this.dialog.close(this.expirationDate);
  }

  close() {
    this.dialog.close();
  }
}
