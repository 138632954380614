import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@helpers/helper';
import { GoodsReceipt, GoodsReceiptLine } from '@models/goodsReceipt';

export enum ISortDirection {
  ASC,
  DESC,
}

@Injectable({
  providedIn: 'root',
})
export class GoodsReceiptService {
  constructor(private http: HttpClient) {}

  getReceipt(id): Observable<GoodsReceipt> {
    return this.http.get<GoodsReceipt>(`${environment.serviceUrl}/goodsReceipt/${id}`, httpOptions);
  }

  getReceipts(): Observable<GoodsReceipt[]> {
    return this.http.get<GoodsReceipt[]>(`${environment.serviceUrl}/goodsReceipt/`, httpOptions);
  }

  addReceipt(goodsReceipt): Observable<GoodsReceipt> {
    return this.http.post<GoodsReceipt>(`${environment.serviceUrl}/goodsReceipt`, goodsReceipt, httpOptions);
  }

  updateReceipt(goodsReceipt: GoodsReceipt): Observable<GoodsReceipt> {
    return this.http.put<GoodsReceipt>(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceipt.id}`,
      goodsReceipt,
      httpOptions
    );
  }

  deleteReceipt(id): Observable<GoodsReceipt> {
    return this.http.delete<GoodsReceipt>(`${environment.serviceUrl}/goodsReceipt/${id}`, httpOptions);
  }

  closeGoodsReceipt(goodsReceipt: GoodsReceipt): Observable<any> {
    return this.http.put(`${environment.serviceUrl}/goodsReceipt/${goodsReceipt.id}/complete`, {}, httpOptions);
  }

  getGoodsReceiptLine(goodsReceiptId: number, goodsReceiptLineId: number): Observable<GoodsReceiptLine> {
    return this.http.get<GoodsReceiptLine>(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceiptId}/goodsReceiptLine/${goodsReceiptLineId}`,
      httpOptions
    );
  }

  updateReceiptLine(goodsReceiptId, goodsReceiptLine: GoodsReceiptLine): Observable<GoodsReceipt> {
    return this.http.put<GoodsReceipt>(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceiptId}/goodsReceiptLine/${goodsReceiptLine.id}`,
      goodsReceiptLine,
      httpOptions
    );
  }

  deleteReceiptLine(goodsReceipt, id): Observable<any> {
    return this.http.delete(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceipt}/goodsReceiptLine/${id}`,
      httpOptions
    );
  }

  addReceiptLine(goodsReceiptLine, id): Observable<GoodsReceipt> {
    return this.http.post<GoodsReceipt>(
      `${environment.serviceUrl}/goodsReceipt/${id}/goodsReceiptLine`,
      goodsReceiptLine,
      httpOptions
    );
  }

  receiveReceiptLine(goodsReceiptLine: GoodsReceiptLine, goodsReceiptId: number): Observable<any> {
    return this.http.post(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceiptId}/goodsReceiptLine/receiveLine`,
      goodsReceiptLine,
      httpOptions
    );
  }

  assignLocation(goodsReceiptId: number, locationId: number): Observable<any> {
    return this.http.post<GoodsReceipt>(
      `${environment.serviceUrl}/goodsReceipt/${goodsReceiptId}/assignlocation/${locationId}`,
      httpOptions
    );
  }
}
