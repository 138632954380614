import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-progress-bar',
  templateUrl: './task-progress-bar.component.html',
  styleUrls: ['./task-progress-bar.component.scss'],
})
export class TaskProgressBarComponent implements OnInit {
  /**
   * The number of completed tasks/items
   */
  @Input() completed: number;
  /**
   * The total number of tasks/items
   */
  @Input() total: number;

  value: number;

  constructor() {}

  ngOnInit(): void {
    this.total = this.total ?? 0;
    this.completed = this.completed ?? 0;

    this.value = (100 / this.total) * this.completed;
  }
}
