import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { HeadingComponent } from '@components/heading/heading.component';
import { EmptyListComponent } from '@components/item-list/empty-list/empty-list.component';
import { ItemQuantityListComponent } from '@components/item-list/item-quantity-list/item-quantity-list.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { LoadingOverlayComponent } from '@components/loading-overlay/loading-overlay.component';
import { NavToolBarComponentComponent } from '@components/nav-tool-bar-component/nav-tool-bar-component.component';
import { QuantityPickerComponent } from '@components/quantity-picker/quantity-picker.component';
import { RepeaterComponent } from '@components/repeater/repeater.component';
import { SearchInputComponent } from '@components/search-input/search-input.component';
import { SideNavComponent } from '@components/side-nav/side-nav.component';
import { SuccessfulResultComponent } from '@components/successful-result/successful-result.component';
import { TaskProgressBarComponent } from '@components/task-progress-bar/task-progress-bar.component';

import { MaterialModule } from '../material design/material.module';
import { ExpandableListComponent } from './item-list/list/expandable-list.component';
import { ListComponent } from './item-list/list/list.component';
import { MoreMenuBottomsheetComponent } from './more-menu-bottomsheet/more-menu-bottomsheet.component';
import { StickyContainerComponent } from './sticky-container/sticky-container.component';

const components = [
  HeadingComponent,
  SideNavComponent,
  NavToolBarComponentComponent,
  AvatarComponent,
  RepeaterComponent,
  EmptyListComponent,
  ItemQuantityListComponent,
  SearchInputComponent,
  LoaderComponent,
  QuantityPickerComponent,
  LoadingOverlayComponent,
  SuccessfulResultComponent,
  TaskProgressBarComponent,
  ListComponent,
  ExpandableListComponent,
  StickyContainerComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, MaterialModule, MatTooltipModule],
  declarations: [...components, MoreMenuBottomsheetComponent],
  exports: [...components],
})
export class SharedComponentsModule {}
