import { HttpHeaders } from '@angular/common/http';

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export function convertToUPCA(barcode) {
  const endpart = barcode.substring(3, 6);
  const startPart = barcode.substring(1, 3);

  return `000${startPart}00000${endpart}`;
}

export function convertToEight(barcode) {
  return `0${barcode}0`;
}

export function padBarcode(str, max) {
  str = str.toString();

  return str.length < max ? pad(`0${str}`, max) : str;
}

function pad(num, size) {
  if (num.toString().length >= size) {
    return num;
  }

  return (Math.pow(10, size) + Math.floor(num)).toString().substring(1);
}

export function parseJsonStringToObject(json: string): Array<any> | null {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
}
