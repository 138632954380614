<mat-dialog-content>
  <label>Scan new boxcode</label>
  <mat-form-field class="barcode-input">
    <input
      matInput
      placeholder="Scan a barcode..."
      autocomplete="off"
      value=""
      #barcode
      [(ngModel)]="scannedData"
      appAutofocus />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions style="margin-top: 50px">
  <div style="display: block; width: 100%">
    <button class="mat-raised-button cancel-button mat-primary" (click)="close()">Cancel</button>
  </div>
</mat-dialog-actions>
