import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Cache from '@helpers/cache';
import { httpOptions } from '@helpers/helper';
import { Store } from '@models/store';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends Cache<Store[]> {
  constructor(private http: HttpClient) {
    super();
  }

  getPrevious(cacheId: number = 0): Promise<Store[]> {
    if (!this.cache.has(cacheId)) {
      this.getStores();
    }

    return this.cache.get(cacheId);
  }

  getStores(): Observable<Store[]> {
    const str = this.http.get<Store[]>(`${environment.serviceUrl}/store`, httpOptions);

    this.cache.set(0, str.toPromise());
    this.hasPrev = true;

    return str;
  }

  getSelectedStore(): Store {
    const store: Store = JSON.parse(localStorage.getItem('WMS_Store'));

    return store ? store : { name: 'No store selected', id: 0 };
  }

  storeSelectedStore(store: Store) {
    localStorage.setItem('WMS_Store', JSON.stringify(store));
  }
}
