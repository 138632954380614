import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { MoreMenuBottomsheetComponent } from '@components/more-menu-bottomsheet/more-menu-bottomsheet.component';
import { SearchInputComponent } from '@components/search-input/search-input.component';
import { DeleteLineDialogComponent } from '@dialogs/delete-line-dialog/delete-line-dialog.component';
import { GoodsReceiptDeleteDialogComponent } from '@dialogs/goods-receipt-delete-dialog/goods-receipt-delete-dialog.component';
import { ItemNotFoundDialogComponent } from '@dialogs/item-not-found-dialog/item-not-found-dialog.component';
import { NotInPicklistDialogComponent } from '@dialogs/not-in-picklist-dialog/not-in-picklist-dialog.component';
import { GoodsReceipt, GoodsReceiptLine } from '@models/goodsReceipt';
import { Item } from '@models/item';
import { Location } from '@models/location';
import { CommunicationService } from '@services/communicationService';
import { DialogService } from '@services/dialog.service';
import { GoodsReceiptService } from '@services/goodReceiptService';
import { ItemService } from '@services/itemService';
import { SnackBarService } from '@services/snackBar.service';

import { LocationsBottomsheetComponent } from '../components/locations-bottomsheet/locations-bottomsheet.component';
import { GoodsreceiptPermission } from '../goodsreceipt.permission';

export enum KEY_CODE {
  TAB = 9,
  ENTER = 13,
}

@Component({
  selector: 'app-receive-items',
  templateUrl: './receive-items.component.html',
  styleUrls: ['./receive-items.component.scss'],
})
export class ReceiveItemsComponent implements OnInit {
  @ViewChild('barcode') barcodeInput: SearchInputComponent;

  @ViewChild('barcodeInput1') barcodeInput1: SearchInputComponent;
  @ViewChild('barcodeInput2') barcodeInput2: SearchInputComponent;
  @ViewChild('matTabGroup') tabGroup: MatTabGroup;

  goodsReceipt: GoodsReceipt = new GoodsReceipt();
  linesDoneGroupedByLocation: Array<{ location: Location; lines: GoodsReceiptLine[] }>;
  linesDoneCount = 0;
  linesToDo: GoodsReceiptLine[];
  linesToDoCount = 0;
  items: Item[];
  isLoading = true;

  title: string;
  subtitle: string;
  convertedBarcode: string;
  selectedTab: number;

  constructor(
    private communicationService: CommunicationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private itemService: ItemService,
    private goodsReceiptService: GoodsReceiptService,
    private bottomSheet: MatBottomSheet,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private goodsReceiptPermission: GoodsreceiptPermission
  ) {
    this.selectedTab = parseInt(route.snapshot.queryParamMap.get('tab'), 10) ?? 0;
  }

  ngOnInit() {
    this.goodsReceiptService.getReceipt(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (data) => {
        this.goodsReceipt = new GoodsReceipt(data);

        if (!this.goodsReceiptPermission.canReceive(this.goodsReceipt)) return;

        this.linesDoneGroupedByLocation = this.goodsReceipt.getDoneLinesGroupedByLocation();
        this.linesDoneCount = this.goodsReceipt.getDoneLines().length;

        this.linesToDo = this.goodsReceipt.getToDoLines();
        this.linesToDoCount = this.linesToDo.length;

        this.title = this.goodsReceipt.supplier.name;
        this.subtitle = this.goodsReceipt.getNavigationSubtitle();

        this.isLoading = false;
      },
      error: () => {
        this.dialogService.openErrorDialog(['Goods receipt could not be found.']);
      },
    });

    this.communicationService.emitChange();
  }

  tabChanged(index: number) {
    this.selectedTab = index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: index },
    });
  }

  searchItem() {
    this.router.navigate([`/searchItem/${this.goodsReceipt.id}/8`]);
  }

  discardItem(line: GoodsReceiptLine) {
    const dialog = this.dialogService.open(DeleteLineDialogComponent);

    dialog.afterClosed().subscribe((data) => {
      debugger;

      if (data) {
        this.goodsReceiptService.deleteReceiptLine(this.goodsReceipt.id, line.id).subscribe(() => {
          this.goodsReceipt.goodsReceiptLines = this.goodsReceipt.goodsReceiptLines.filter((gr) => gr.id !== line.id);
          this.linesDoneGroupedByLocation = this.goodsReceipt.getLinesGroupedByLocation();
        });
      }

      this.barcodeInput.focus();
    });
  }

  openItem(line: GoodsReceiptLine) {
    debugger;
    this.router.navigate([`receive/${this.goodsReceipt.id}/line/${line.id}`]);
  }

  updateItem(line: GoodsReceiptLine) {
    this.router.navigate([`receive/${this.goodsReceipt.id}/line/${line.id}/update`]);
  }

  closeReceipt() {
    this.router.navigate([`../confirmation`], { relativeTo: this.route });
  }

  showMoreMenu() {
    const options = [{ icon: 'trip_origin', text: 'Location', action: 'location' }];

    if (this.goodsReceipt.isFreeReceipt) {
      options.unshift({ icon: 'delete_forever', text: 'Discard', action: 'delete' });
    }

    const bottomSheetRef = this.bottomSheet.open(MoreMenuBottomsheetComponent, {
      data: {
        options,
      },
    });

    bottomSheetRef.afterDismissed().subscribe((action: string) => {
      if (action != undefined) {
        switch (action) {
          case 'delete':
            this.discardGoodReceipt();
            break;
          case 'location':
            this.openLocationBottomSheet();
            break;
          default:
            break;
        }
      }
    });
  }

  openLocationBottomSheet() {
    const locationBottomSheet = this.bottomSheet.open(LocationsBottomsheetComponent, {
      data: { goodsReceipt: this.goodsReceipt },
    });

    locationBottomSheet.afterDismissed().subscribe((data: { shouldRefresh?: boolean; scanLocation?: boolean }) => {
      if (data.shouldRefresh) {
        this.isLoading = true;
        this.ngOnInit();
      }

      if (data.scanLocation) {
        this.router.navigate([`receive/${this.goodsReceipt.id}/location`], { queryParams: { allowed: true } });
      }
    });
  }

  discardGoodReceipt() {
    const dialog = this.dialogService.open(GoodsReceiptDeleteDialogComponent);

    dialog.afterClosed().subscribe((discard) => {
      if (discard) {
        this.goodsReceiptService.deleteReceipt(this.goodsReceipt.id).subscribe(() => {
          this.snackBarService.open(`Free receipt from ${this.goodsReceipt.supplier.name} was removed.`);
          this.router.navigate(['/receive'], { queryParams: { tab: 1 } });
        });
      }
    });
  }

  searchSubmitted(scannedData: string) {
    this.convertedBarcode = SearchInputComponent.convertInputToBarcode(scannedData);

    const foundItemInLine = this.goodsReceipt.goodsReceiptLines.find((x) => x.item.barcode === this.convertedBarcode);

    if (foundItemInLine) {
      this.router.navigate([`receive/${this.goodsReceipt.id}/item/${foundItemInLine.id}/update`]);
    } else {
      const dialog = this.dialogService.open(NotInPicklistDialogComponent);

      dialog.afterClosed().subscribe(() => {
        scannedData = null;
        this.barcodeInput1.focus();
        this.barcodeInput1.clearSearch();

        this.barcodeInput2.focus();
        this.barcodeInput2.clearSearch();
      });
    }
  }

  barcodeScanned(barcode: string) {
    barcode = SearchInputComponent.convertInputToBarcode(barcode);

    this.itemService.getItemByBarcode(barcode).subscribe(
      (res) => {
        this.router.navigate([`receive/${this.goodsReceipt.id}/item/${res.barcode}/add`]);
      },
      () => {
        const dialog = this.dialogService.open(ItemNotFoundDialogComponent, {
          receiptId: this.goodsReceipt.id,
        });

        dialog.afterClosed().subscribe((data) => {
          if (data) {
            this.router.navigate([`/searchItem/${this.goodsReceipt.id}/8`]);
          } else {
            this.barcodeInput.focus();
            this.barcodeInput.clearSearch();
          }
        });
      }
    );
  }
}
