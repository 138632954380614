<ng-container *ngIf="!skeleton">
  <mat-list-item class="dashboard-list-item" (click)="open($event)" [disabled]="isDisabled">
    <div class="container" style="width: 100%">
      <div class="column-left item-list">
        <div mat-line class="item-list-item-name"><span class="{{ dotColor }}"></span>{{ line.item.name }}</div>
        <div mat-line class="sub-item-list-name">{{ line.item.barcode }}</div>
        <div mat-line class="sub-item-list-name">{{ line.unitOfMeasurement.quantity }} x {{ line.item.size }}</div>
      </div>
      <div class="column-center item-quantity">
        <div mat-line class="item-quantity-color {{ dotColor }}">
          {{ onlyAmount ? '' : quantityPicked() + ' /' }} {{ line.quantity }}
        </div>
        <div mat-line class="sub-item-list-name">
          {{ line.unitOfMeasurement.name }}
        </div>
      </div>
      <div class="column-right" style="width: 24px; order: 3; text-align: end" *ngIf="isDiscardable">
        <mat-icon class="menu-button goods-receipt-delete" (click)="discard($event)">clear</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
  </mat-list-item>
</ng-container>

<ng-container *ngIf="skeleton">
  <mat-list-item class="dashboard-list-item" style="background-color: #0d141a">
    <div class="container" style="width: 100%">
      <div class="float-left item-list" style="width: calc(100% - 48px)">
        <div mat-line class="item-list-item-name placeholder placeholder-lg" style="margin: 5px 0"></div>
        <div mat-line class="sub-item-list-name placeholder placeholder-md" style="margin: 5px 0"></div>
        <div mat-line class="sub-item-list-name placeholder placeholder-sm" style="margin: 5px 0"></div>
      </div>
      <div class="float-right item-quantity" style="width: 48px">
        <div mat-line class="item-quantity-color placeholder placeholder-md float-right" style="margin: 5px 0"></div>
        <div mat-line class="sub-item-list-name placeholder placeholder-lg float-right"></div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </mat-list-item>
</ng-container>
