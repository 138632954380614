import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-goods-receipt-delete-dialog',
  templateUrl: './goods-receipt-delete-dialog.component.html',
  styleUrls: ['./goods-receipt-delete-dialog.component.scss'],
})
export class GoodsReceiptDeleteDialogComponent {
  constructor(private dialog: MatDialogRef<GoodsReceiptDeleteDialogComponent>) {}

  save() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }
}
