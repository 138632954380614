import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Cache from '@helpers/cache';
import { httpOptions } from '@helpers/helper';
import { User } from '@models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends Cache<User> {
  constructor(private http: HttpClient) {
    super();
  }

  updateUser(user): Observable<User> {
    return this.http.put<User>(`${environment.serviceUrl}/security/updateUser`, user, httpOptions);
  }

  getUser() {
    const usrObs = this.http.get<User>(`${environment.serviceUrl}/me/user`, httpOptions);

    this.cache.set(0, usrObs.toPromise());
    this.hasPrev = true;

    return usrObs;
  }

  getPrevious(cacheId: number = 0): Promise<User> {
    if (!this.cache.has(cacheId)) {
      this.getUser();
    }

    return this.cache.get(cacheId);
  }
}
