import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { convertToEight, convertToUPCA, padBarcode } from '@helpers/helper';
import { KEY_CODE } from '@modules/receiving/receive-items/receive-items.component';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements AfterViewInit {
  /**
   * The title to be displayed inside the barcode scanner box.
   */
  @Input() inputTitle = 'Scan a barcode';

  /**
   * The search value, can be set here to include an initial value or act upon it with bindings.
   * NOTE: 2-way binding is supported.
   */
  @Input() searchValue: string;
  @Output() searchValueChange = new EventEmitter<string>();

  /**
   * Emitted when the user presses ENTER and the search value length is greater than 0.
   */
  @Output() submitSearch = new EventEmitter<string>();

  /**
   * An optional icon to be placed at the right hand side of the input field.
   */
  @Input() icon: string;

  @Output() iconPress = new EventEmitter<undefined>();

  @Input() hideVirtualKeyboard: boolean;
  /**
   * Indicates if this component should listen for TAB keys and auto-focus the input.
   */
  @Input() autoFocusOnTab = true;

  @ViewChild('barcode') barcodeInput: ElementRef<HTMLInputElement>;

  constructor() {}

  static convertInputToBarcode(input: string): string {
    let output;

    // EAN-13
    if (input.length > 12) {
      output = input;
    }

    if (input.length === 12) {
      output = padBarcode(input.substring(0, 11), 13);
    }

    if (input.length < 10) {
      output = input;

      if (input.length === 6) {
        output = convertToEight(input);
      }

      if (input.length === 8) {
        output = convertToUPCA(input);
      }

      output = padBarcode(output, 13);
    }

    return output;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.autoFocusOnTab && event.keyCode === KEY_CODE.TAB) {
      if (document.body.contains(this.barcodeInput.nativeElement)) {
        this.focus();
        event.preventDefault();
      }
    }
  }

  @HostListener('click', ['$event']) onClick(e) {
    this.barcodeInput.nativeElement.focus();

    if (e.target.className === 'mat-line' || e.target.className === 'mat-list-text') {
    } else {
      this.barcodeInput.nativeElement.focus();
    }
  }

  iconPressed() {
    this.iconPress.emit();
  }

  focus() {
    this.barcodeInput.nativeElement.focus();
  }

  handleVirtualKeyboard() {
    const element = this.barcodeInput.nativeElement;

    if (this.hideVirtualKeyboard) {
      element.readOnly = true;
      element.focus();

      setTimeout(() => {
        element.readOnly = false;
      }, 50);
    } else {
      element.focus();
    }
  }

  ngAfterViewInit(): void {
    this.handleVirtualKeyboard();

    this.barcodeInput.nativeElement.addEventListener('keyup', (e) => {
      if (e.keyCode === KEY_CODE.ENTER && this.searchValue?.length > 0) {
        this.submitSearch.emit(this.searchValue);
        e.preventDefault();
      } else {
        this.searchValueChange.emit(this.searchValue);
      }
    });
  }

  clearSearch() {
    this.searchValue = '';
  }
}
