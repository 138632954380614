<mat-sidenav-container
  #MatSidenavContainer
  class="container-background"
  [ngStyle]="{ position: sidenav.opened ? 'fixed' : 'relative' }">
  <mat-sidenav id="mainSideNav" class="sidemenu-background" [position]="position" [fixedInViewport]="true" #sidenav>
    <ng-content select="[sideContent]"></ng-content>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content select="[body]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
