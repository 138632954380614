<mat-list class="quantity-picker-top-container">
  <div *ngIf="title" mat-subheader>{{ title }}</div>
  <mat-form-field style="padding-top: 0px" class="barcode-input-field-outline" appearance="outline">
    <input
      type="number"
      (click)="inputPressed($event)"
      class="quantity-picker-input"
      matInput
      (ngModelChange)="changeAmount($event)"
      (focusin)="selectInputText($event)"
      (focusout)="checkAmount()"
      [ngModel]="quantity"
      autocomplete="off"
      placeholder="" />
    <span *ngIf="maxQuantity" style="margin-right: 24px; font-weight: 500; font-size: 20px; color: #ffffff60" matSuffix>
      / {{ maxQuantity }}</span
    >
    <button (click)="decrement($event)" mat-icon-button matPrefix>
      <mat-icon class="quantity-icon remove_circle_outline">remove_circle_outline</mat-icon>
    </button>
    <button (click)="increment($event)" mat-icon-button matSuffix>
      <mat-icon class="quantity-icon add_circle_outline">add_circle_outline</mat-icon>
    </button>
  </mat-form-field>
  <mat-divider></mat-divider>
</mat-list>

<app-sticky-container position="bottom" *ngIf="!hideSubmitButton">
  <button class="button_green" (click)="confirm()" mat-button>{{ submitButtonText }}</button>
</app-sticky-container>
