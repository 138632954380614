import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { GoodsReceipt } from '@models/goodsReceipt';
import { Location, LocationStatus } from '@models/location';
import { DialogService } from '@services/dialog.service';
import { GoodsReceiptService } from '@services/goodReceiptService';
import { SnackBarService } from '@services/snackBar.service';

@Component({
  selector: 'app-locations-bottomsheet',
  templateUrl: './locations-bottomsheet.component.html',
  styleUrls: ['./locations-bottomsheet.component.scss'],
})
export class LocationsBottomsheetComponent implements OnInit {
  goodsReceipt: GoodsReceipt;

  constructor(
    public bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private goodsReceiptService: GoodsReceiptService
  ) {
    this.goodsReceipt = data.goodsReceipt;
  }

  ngOnInit(): void {
    this.goodsReceiptService.getReceipt(this.goodsReceipt.id).subscribe((data) => {
      this.goodsReceipt = new GoodsReceipt(data);
    });
  }

  selectLocation(location: Location) {
    if (location.status != LocationStatus.AVAILABLE) {
      return;
    }

    this.goodsReceiptService.assignLocation(this.goodsReceipt.id, location.id).subscribe({
      next: () => {
        this.snackBarService.open(`Changed location to ${location.name}`);
        this.bottomSheet.dismiss({ shouldRefresh: true });
      },
      error: () => {
        this.dialogService.openErrorDialog(['Something went wrong while selecting this location']);
      },
    });
  }

  addLocation() {
    this.bottomSheet.dismiss({ scanLocation: true });
  }

  close() {
    this.bottomSheet.dismiss();
  }

  isDisabled(location: Location): boolean {
    return location.status != LocationStatus.AVAILABLE;
  }

  isCurrentLocation(location: Location): boolean {
    return location.id === this.goodsReceipt.locationId;
  }
}
