import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ICancelDialogProps {
  name: string;
  cancelLabel?: string;
  confirmLabel?: string;
  isWarning?: boolean;
}

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent implements OnInit {
  title: string;

  constructor(private dialog: MatDialogRef<CancelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.title = this.data.name;
  }

  save() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }
}
