<mat-card style="text-align: center; background-color: #242d35">
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p>
        <mat-form-field appearance="outline" [disabled]="true">
          <mat-label>Username</mat-label>
          <input type="email" matInput placeholder="Username" formControlName="email" [disabled]="true" />
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline" class="border" [disabled]="showSpinner">
          <mat-label>Password</mat-label>
          <input
            type="{{ showPassword ? 'text' : 'password' }}"
            matInput
            placeholder="Password"
            formControlName="password" />
          <mat-icon matSuffix (click)="showPassword = !showPassword">{{
            showPassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </mat-form-field>
      </p>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <div class="button">
        <mat-spinner mode="indeterminate" value="50" [diameter]="16" *ngIf="showSpinner"> </mat-spinner>
        <button type="submit" mat-button [disabled]="showSpinner">Login</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
