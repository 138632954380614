import { AlternateBarcode } from './alternateBarcode';
import { BaseModel } from './basemodel';
import { Brand } from './brand';
import { Supplier } from './supplier';
import { UnitOfMeasurement } from './unitOfMeasure';

export class Item extends BaseModel {
  id: number;
  name: string;
  barcode: string;
  size: string;
  brand: Brand;
  unitOfMeasurements: UnitOfMeasurement[];
  alternateBarcodes: AlternateBarcode[];
  supplier: Supplier;
  price: number;
}
