import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-item-not-found-dialog',
  templateUrl: './item-not-found-dialog.component.html',
  styleUrls: ['./item-not-found-dialog.component.scss'],
})
export class ItemNotFoundDialogComponent {
  receiptId: string;

  constructor(private dialog: MatDialogRef<ItemNotFoundDialogComponent>) {}

  search() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }
}
