import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitOfMeasurement } from '@models/unitOfMeasure';

@Component({
  selector: 'app-uom-dialog',
  templateUrl: './uom-dialog.component.html',
  styleUrls: ['./uom-dialog.component.scss'],
})
export class UomDialogComponent implements OnInit {
  chosenUOM: UnitOfMeasurement;

  constructor(private dialog: MatDialogRef<UomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.chosenUOM = this.data.chosenUom;
  }

  save() {
    this.dialog.close(this.chosenUOM);
  }

  close() {
    this.dialog.close();
  }
}
