/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import pkg from '../../package.json';

export const environment = {
  production: false,
  serviceUrl: 'https://wms-api.test.businessone.app/api',
  version: pkg.version,
  sentryDSN:
    'https://3b1399cc27fb4ee2b2a20fc1c6023fe4@o413822.ingest.sentry.io/6114520',
};
