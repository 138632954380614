<ng-container *ngIf="count">
  <mat-accordion displayMode="flat" hideToggle multi [class.mat-elevation-z0]="true">
    <mat-expansion-panel [expanded]="!isExpanded" [class.mat-elevation-z0]="true">
      <mat-expansion-panel-header
        collapsedHeight="32px"
        expandedHeight="24px"
        [ngClass]="[isDisabled ? 'appear-disabled' : '']">
        <mat-panel-title ngClass="contrast-87">
          {{ header }}
          <ng-content select="[header]"></ng-content>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <ng-content></ng-content>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-container *ngIf="!count">
  <app-empty-list [header]="headerIfEmpty"></app-empty-list>
</ng-container>
