import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  config: MatSnackBarConfig = {
    duration: 2000,
    panelClass: 'add-item-snackbar',
  };

  constructor(private matSnackBar: MatSnackBar) {}

  open(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
    return this.matSnackBar.open(message, action, { ...this.config, ...config });
  }
}
