import { BaseModel, TimeStampedBaseModel } from '@models/basemodel';
import { Item } from '@models/item';
import { Location } from '@models/location';
import { Supplier } from '@models/supplier';
import { UnitOfMeasurement } from '@models/unitOfMeasure';

export class GoodsReceipt extends TimeStampedBaseModel {
  id: number;
  referenceNumber: string;
  receiptNumber: string;
  status: GoodsReceiptStatus;
  progress: string;
  goodsReceiptLines: GoodsReceiptLine[];
  storeId: number;
  type: GoodsReceiptType;
  externalId: string;

  locationId: number;
  private _location: Location;

  supplierId: number;
  private _supplier: Supplier;

  private _usedLocations: Location[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      this.location = new Location();
      this.supplier = new Supplier();
    }

    this.setGoodsReceiptLines(data?.goodsReceiptLines);
  }

  set location(data: any) {
    this._location = new Location(data);
    this.locationId = this._location.id;
  }

  get location() {
    return this._location;
  }

  set supplier(data: any) {
    this._supplier = new Supplier(data);
    this.supplierId = this._supplier.id;
  }

  get supplier(): Supplier {
    return this._supplier;
  }

  set usedLocations(data: Array<Location>) {
    if (data) {
      this._usedLocations = data.map((locationData: any) => new Location(locationData));
    }
  }

  get usedLocations(): Location[] {
    return this._usedLocations;
  }

  get isFreeReceipt(): boolean {
    return this.type == GoodsReceiptType.FREERECEIPT;
  }

  get isTodo() {
    return this.status == GoodsReceiptStatus.OPEN;
  }

  get isInProgress() {
    return this.status == GoodsReceiptStatus.RECEIVING;
  }

  get isDone() {
    return !![GoodsReceiptStatus.RECEIVED, GoodsReceiptStatus.SYNCED].find((status) => status == this.status);
  }

  getItemsSummary(): string {
    return this.goodsReceiptLines.reduce((p, { item }, i) => (p += (i !== 0 ? ', ' : '') + item.name), '');
  }

  /**
   * Get all lines from goods receipt that still need to be done
   * @returns an array of Goods Receipt lines that are done
   */
  getToDoLines(): GoodsReceiptLine[] {
    return this.goodsReceiptLines.filter((line: GoodsReceiptLine) => line.status === 0 || !line.status);
  }

  /**
   * Incomplete lines are lines that have the status 0 (TODO) but have a quantity that
   * is greater than 0 but less than the max quantity (started but not completed).
   * @returns an array of Goods Receipt lines
   */
  getIncompleteLines(): GoodsReceiptLine[] {
    return this.goodsReceiptLines.filter(
      (line: GoodsReceiptLine) =>
        (line.status === 0 || !line.status) && line.receivedQuantity < line.quantity && line.receivedQuantity > 0
    );
  }

  /**
   * Gets all the lines that have the TODO status and have a received quantity of 0.
   * @returns an array of Goods Receipt lines
   */
  getEmptyTodoLines(): GoodsReceiptLine[] {
    return this.getToDoLines().filter((x) => x.receivedQuantity === 0);
  }

  getDoneLines(): GoodsReceiptLine[] {
    return this.goodsReceiptLines.filter((line: GoodsReceiptLine) => line.status === 1);
  }

  setGoodsReceiptLines(data: Array<any> = []) {
    this.goodsReceiptLines = data.map((goodsReceiptLineData) => new GoodsReceiptLine(goodsReceiptLineData));
  }

  getNavigationSubtitle(): string {
    const prefix = this.isFreeReceipt ? `Free Receipt` : `PO Receipt ${this.receiptNumber}`;

    return this.referenceNumber ? `${prefix} - ${this.referenceNumber}` : prefix;
  }

  getLinesGroupedByLocation(): LinesGroupedByLocation[] {
    return GroupLinesByLocation(this.goodsReceiptLines, [{ location: this.location, lines: [] }]);
  }

  getDoneLinesGroupedByLocation(includeCurrentLocation = true): LinesGroupedByLocation[] {
    return GroupLinesByLocation(
      this.getDoneLines(),
      includeCurrentLocation ? [{ location: this.location, lines: [] }] : []
    );
  }

  getIncompleteLinesGroupedByLocation(includeCurrentLocation = true): LinesGroupedByLocation[] {
    return GroupLinesByLocation(
      this.getIncompleteLines(),
      includeCurrentLocation ? [{ location: this.location, lines: [] }] : []
    );
  }
}

/**
 * This method reduces and array of lines to an array of lines grouped by the same location
 * @param lines: the lines that need to be grouped by location
 * @param initialValue the initial value
 * @returns an array of objects containing a location object and the lines received on that same location
 */
export const GroupLinesByLocation = (lines: GoodsReceiptLine[], initialValue = []): LinesGroupedByLocation[] => {
  return lines.reduce((acc: LinesGroupedByLocation[], grl: GoodsReceiptLine) => {
    const index = acc.findIndex((g) => g.location.id === grl.locationId);

    if (index > -1) {
      acc[index].lines.push(grl);
    } else {
      acc.push({ location: grl.location, lines: [grl] });
    }

    return acc;
  }, initialValue);
};

export class GoodsReceiptLine extends BaseModel {
  id: number;
  name: string;
  quantity: number;
  pickedQuantity;
  receivedQuantity;
  remark: string;
  status: GoodsReceiptLineStatus;

  _unitOfMeasurement: UnitOfMeasurement;
  unitOfMeasurementId: number;

  _item: Item;
  itemId: number;

  _goodsReceipt: GoodsReceipt;
  goodsReceiptId: number;

  _location: Location;
  locationId;

  constructor(data?: any) {
    super(data);

    if (!data) {
      this.unitOfMeasurement = new UnitOfMeasurement();
    }
  }

  set goodsReceipt(data) {
    this._goodsReceipt = new GoodsReceipt(data);

    this.goodsReceiptId = this._goodsReceipt.id;
  }

  get goodsReceipt() {
    return this._goodsReceipt;
  }

  set unitOfMeasurement(data: any) {
    this._unitOfMeasurement = new UnitOfMeasurement(data);
    this.unitOfMeasurementId = this._unitOfMeasurement.id;
  }

  get unitOfMeasurement() {
    return this._unitOfMeasurement;
  }

  set item(data: any) {
    this._item = new Item(data);
    this.itemId = this._item.id;
  }

  get item() {
    return this._item;
  }

  set location(data: any) {
    this._location = new Location(data);
    this.locationId = this._location.id;
  }

  get location() {
    return this._location;
  }
}

export class EmptyReceipt extends BaseModel {
  supplierId: number;
  referenceNumber: string;
  remark: string;
  storeId: number;
}

export type LinesGroupedByLocation = {
  lines: GoodsReceiptLine[];
  location: Location;
};

export enum GoodsReceiptStatus {
  OPEN = 'Receive_Todo',
  RECEIVING = 'Receive_InProgress',
  RECEIVED = 'Receive_Done',
  SYNCED = 'Received_SAP',
  PUTTINGAWAY = 'PuttingAway',
  DONEPUTTINGAWAY = 'DonePuttingAway',
}

export enum GoodsReceiptLineStatus {
  OPEN = 0,
  RECEIVED = 1,
  DONEPUTTINGNAWAY = 2,
}

export enum GoodsReceiptType {
  FREERECEIPT = 'FreeReceipt',
  PURCHASEORDER = 'PurchaseOrder',
  CONTAINER = 'Container',
}
