<app-nav-tool-bar-component
  [navigation]="navigation"
  [iconType]="iconType"
  (valueChange)="nextClick()"
  [navigationTitle]="navigationTitle"
  [navigationSubtitle]="line"></app-nav-tool-bar-component>

<app-loader [show]="isLoading">
  <app-loading-overlay></app-loading-overlay>
</app-loader>

<mat-list class="mat-list-info" style="padding-top: 0px" *ngIf="linesPicked.length > 0">
  <div mat-header class="mat-header done">{{ navState.linesPickedTitle || 'Picked' }}</div>

  <ng-container *ngFor="let line of linesPicked">
    <app-item-quantity-list [onlyAmount]="true" [dotColor]="'green-dot'" [line]="line"></app-item-quantity-list>
  </ng-container>
</mat-list>

<mat-list class="mat-list-info" *ngIf="linesDone.length > 0">
  <div mat-header class="mat-header incomplete">{{ navState.linesDoneTitle || 'Complete' }}</div>

  <ng-container *ngFor="let line of linesDone">
    <app-item-quantity-list [dotColor]="'green-dot'" [type]="'done'" [line]="line"></app-item-quantity-list>
  </ng-container>
</mat-list>

<mat-list class="mat-list-info" *ngIf="linesToDo.length > 0">
  <div mat-header class="mat-header incomplete">{{ navState.linesToDoTitle || 'Incomplete' }}</div>

  <ng-container *ngFor="let line of linesToDo">
    <app-item-quantity-list [dotColor]="'orange-dot'" [type]="'todo'" [line]="line"></app-item-quantity-list>
  </ng-container>
</mat-list>

<mat-list class="mat-list-info" *ngIf="linesOpen.length > 0">
  <div mat-header class="mat-header incomplete">{{ navState.linesOpenTitle || 'Not picked' }}</div>

  <ng-container *ngFor="let line of linesOpen">
    <app-item-quantity-list [dotColor]="'red-dot'" [type]="'open'" [line]="line"></app-item-quantity-list>
  </ng-container>
</mat-list>
