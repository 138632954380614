import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-repeat',
  templateUrl: './repeater.component.html',
  styleUrls: ['./repeater.component.scss'],
})
export class RepeaterComponent implements AfterViewInit {
  @Input() times: number;
  @Input() ContainerClass: string;
  @ViewChild('contentWrapper') content: ElementRef<HTMLDivElement>;
  @ViewChild('repeatContainer') container: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit() {
    for (let i = 1; i < this.times; i++) {
      this.container.nativeElement.appendChild(this.content.nativeElement.childNodes[0].cloneNode(true));
    }
  }
}
