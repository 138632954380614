import { Component, Input, OnInit } from '@angular/core';

export enum HeaderType {
  ITEMCOUNT = 'item-count',
  LOCATION = 'location',
}

@Component({
  selector: 'app-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
})
export class ExpandableListComponent implements OnInit {
  @Input() count: number;
  @Input() header: string;

  @Input() headerType: HeaderType;
  @Input() isExpanded: true;
  @Input() isDisabled: false;

  @Input() headerIfEmpty: string;

  constructor() {}

  ngOnInit(): void {
    if (this.headerType === HeaderType.ITEMCOUNT) {
      this.header = `${this.count} item(s)`;
    }
  }
}
