import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-picker',
  templateUrl: './quantity-picker.component.html',
  styleUrls: ['./quantity-picker.component.scss'],
})
export class QuantityPickerComponent {
  @Input() maxQuantity: number | undefined;
  @Input() allowNegative = false;
  @Input() round = false;

  @Input() quantity: number;
  @Output() quantityChange = new EventEmitter<number>();

  /**
   * The title that appears at the top of the picker.
   * set to undefined to remove the title.
   */
  @Input() title: string | undefined = 'Enter quantity';

  @Input() hideSubmitButton = false;
  @Input() submitButtonText = 'Submit';
  /**
   * An event that is triggered when the user presses the submit button.
   * Will send back the quantity at the time of submission.
   */
  @Output() submitQuantity: EventEmitter<number> = new EventEmitter();

  constructor() {}

  isOverLimit() {
    if (this.maxQuantity && this.quantity > this.maxQuantity) {
      return true;
    }

    return false;
  }

  isUnderMinimum() {
    if (this.allowNegative === false && this.quantity < 0) {
      return true;
    }

    return false;
  }

  selectInputText(event: Event) {
    (event.currentTarget as HTMLInputElement).select();
  }

  checkAmount() {
    if (Number.isNaN(this.quantity)) {
      this.quantity = 0;
    }

    if (this.isOverLimit()) {
      this.quantity = this.maxQuantity;
    } else if (this.isUnderMinimum()) {
      this.quantity = 0;
    }

    if (this.round) {
      this.quantity = Math.round(this.quantity);
    }
  }

  changeAmount(val: number) {
    this.quantity = Number(val);
    this.checkAmount();
    this.quantityChange.emit(this.quantity);
  }

  decrement(event: Event) {
    event.stopPropagation();

    this.quantity--;
    this.checkAmount();
    this.quantityChange.emit(this.quantity);
  }

  increment(event: Event) {
    event.stopPropagation();

    this.quantity++;
    this.checkAmount();
    this.quantityChange.emit(this.quantity);
  }

  confirm() {
    this.checkAmount();
    this.submitQuantity.emit(this.quantity);
  }

  inputPressed(e) {
    e.target.select();
  }
}
