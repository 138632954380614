<app-nav-tool-bar-component [hideNextButton]="true" [navigationTitle]="navigationTitle"> </app-nav-tool-bar-component>
<app-loading-overlay *ngIf="isAdding"></app-loading-overlay>
<mat-list>
  <ng-container *ngIf="isLoading">
    <mat-list-item>
      <mat-icon mat-list-icon class="material-icons-outlined">category</mat-icon>
      <div mat-line class="item-list-item-name placeholder placeholder-lg"></div>
      <div mat-line class="sub-item-list-name placeholder placeholder-md"></div>
      <div mat-line class="sub-item-list-name placeholder placeholder-sm"></div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <mat-icon mat-list-icon class="material-icons-outlined">inventory_2</mat-icon>
      <div mat-line class="item-list-item-name placeholder placeholder-sm"></div>
      <div mat-line class="sub-item-list-name placeholder placeholder-md"></div>
      <span class="toolbar-spacer"></span>
    </mat-list-item>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <mat-list-item>
      <mat-icon mat-list-icon class="material-icons-outlined">category</mat-icon>
      <div mat-line class="item-list-item-name">{{ scannedItem.name }}</div>
      <div mat-line class="sub-item-list-name">{{ scannedItem.barcode }}</div>
      <div mat-line class="sub-item-list-name">{{ scannedItem.size }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item>
      <mat-icon mat-list-icon class="material-icons-outlined">inventory_2</mat-icon>
      <div mat-line class="item-list-item-name">{{ chosenUom.name }}</div>
      <div mat-line class="sub-item-list-name">Amount: {{ chosenUom.quantity }}</div>
      <span class="toolbar-spacer"></span>

      <button (click)="changeUom()" *ngIf="!hideChangeUnit" mat-icon-button>
        <mat-icon class="material-icons-outlined">edit</mat-icon>
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>
</mat-list>

<app-quantity-picker
  [submitButtonText]="buttonDescription"
  (submitQuantity)="addItem($event)"
  [(quantity)]="goodsReceiptLine?.receivedQuantity || quantityPicked"
  [maxQuantity]="goodsReceiptLine?.quantity || undefined"
  *ngIf="!isLoading">
</app-quantity-picker>
