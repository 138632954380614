import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorListDialogComponent } from '@dialogs/error-list-dialog/error-list-dialog.component';
import { ExpirationDialogComponent } from '@dialogs/expiration-dialog/expiration-dialog.component';
import { UomDialogComponent } from '@dialogs/uom-dialog/uom-dialog.component';
import { GoodsReceipt, GoodsReceiptLine, GoodsReceiptLineStatus } from '@models/goodsReceipt';
import { Item } from '@models/item';
import { UnitOfMeasurement } from '@models/unitOfMeasure';
import { GoodsreceiptPermission } from '@modules/receiving/goodsreceipt.permission';
import { CommunicationService } from '@services/communicationService';
import { DialogService } from '@services/dialog.service';
import { GoodsReceiptService } from '@services/goodReceiptService';
import { ItemService } from '@services/itemService';
import { SnackBarService } from '@services/snackBar.service';

@Component({
  selector: 'app-item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss'],
})
export class ItemPickerComponent implements OnInit {
  goodsReceipt: GoodsReceipt = new GoodsReceipt();

  goodsReceiptLine: GoodsReceiptLine;
  exisitingGoodsReceiptLine: GoodsReceiptLine = new GoodsReceiptLine();
  newGoodsReceiptLine: GoodsReceiptLine = new GoodsReceiptLine();

  scannedItem: Item = new Item();
  barcode: string;
  uomId: number;

  navigation: string;
  navigationTitle = 'Enter quantity';
  goodsReceiptId: string;

  quantityPicked = 1;
  chosenUom: UnitOfMeasurement = new UnitOfMeasurement();
  expirationDate: Date;

  buttonDescription = 'ADD';

  hideLocation = false;
  hideChangeUnit = false;

  doneLoading = false;
  isLoading = true;
  isAdding = false;

  constructor(
    private communicationService: CommunicationService,
    private itemService: ItemService,
    private dialogService: DialogService,
    private router: Router,
    private location: Location,
    private goodsReceiptService: GoodsReceiptService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,

    private goodsReceiptPermission: GoodsreceiptPermission
  ) {}

  ngOnInit() {
    this.communicationService.emitChange();

    const goodsReceiptId = this.route.snapshot.paramMap.get('id');
    const barcode = this.route.snapshot.paramMap.get('itemId');
    const uomId = Number(this.route.snapshot.paramMap.get('uomId'));
    const lineId = Number(this.route.snapshot.paramMap.get('lineId'));

    this.goodsReceiptService.getReceipt(goodsReceiptId).subscribe((goodsReceiptData) => {
      this.goodsReceipt = new GoodsReceipt(goodsReceiptData);

      if (!this.goodsReceiptPermission.canReceive(this.goodsReceipt)) return;

      this.navigation = `receive/${this.goodsReceipt.id}/receive`;

      if (barcode) {
        this.itemService.getItemByBarcode(barcode).subscribe((res) => {
          this.scannedItem = new Item(res);

          this.chosenUom = uomId
            ? (this.chosenUom = this.scannedItem.unitOfMeasurements.find((e) => e.id === uomId))
            : this.scannedItem.unitOfMeasurements[0];

          this.exisitingGoodsReceiptLine = uomId
            ? this.goodsReceipt.goodsReceiptLines.find(
                (goodReceipt) =>
                  goodReceipt.item.id === this.scannedItem.id && goodReceipt.unitOfMeasurement.id === uomId
              )
            : this.goodsReceipt.goodsReceiptLines.find(
                (goodReceipt) =>
                  goodReceipt.item.id === this.scannedItem.id && goodReceipt.unitOfMeasurement.id === this.chosenUom.id
              );

          this.quantityPicked = this.exisitingGoodsReceiptLine ? this.exisitingGoodsReceiptLine.quantity : 1;

          if (this.exisitingGoodsReceiptLine) {
            this.buttonDescription = 'UPDATE';
          }

          this.isLoading = false;
        });
      }

      if (lineId) {
        this.buttonDescription = 'UPDATE';

        this.exisitingGoodsReceiptLine = this.goodsReceipt.goodsReceiptLines.find((line) => line.id === lineId);

        this.quantityPicked = this.exisitingGoodsReceiptLine.quantity;
        this.scannedItem = this.exisitingGoodsReceiptLine.item;
        this.chosenUom = this.exisitingGoodsReceiptLine.unitOfMeasurement;

        this.isLoading = false;
      }
    });
  }

  addItem(_val: number) {
    this.isAdding = true;

    const goodsReceiptLine = this.exisitingGoodsReceiptLine ? this.exisitingGoodsReceiptLine : this.newGoodsReceiptLine;

    goodsReceiptLine.goodsReceipt = this.goodsReceipt;
    goodsReceiptLine.item = this.scannedItem;
    goodsReceiptLine.quantity = this.quantityPicked;
    goodsReceiptLine.receivedQuantity = this.quantityPicked;
    goodsReceiptLine.unitOfMeasurement = this.chosenUom;

    if (this.exisitingGoodsReceiptLine) {
      this.updateExistingLine(goodsReceiptLine);
    } else {
      goodsReceiptLine.location = this.goodsReceipt.location;
      goodsReceiptLine.receivedQuantity = this.quantityPicked ?? _val;
      goodsReceiptLine.status = GoodsReceiptLineStatus.RECEIVED;

      this.goodsReceipt.goodsReceiptLines.push(goodsReceiptLine);

      this.addLine(goodsReceiptLine);
    }
  }

  updateExistingLine(goodsReceiptLine: GoodsReceiptLine) {
    this.goodsReceiptService.updateReceiptLine(goodsReceiptLine.goodsReceipt.id, goodsReceiptLine).subscribe({
      next: () => {
        this.openSnackBar();
        this.isAdding = false;

        this.router.navigate([this.navigation]);
      },
      error: ({ error }) => {
        this.isAdding = false;

        const snackBar = this.snackBarService.open(error?.title, 'view', {
          panelClass: 'warning-snackbar',
          duration: 5000,
        });

        snackBar.onAction().subscribe(() => {
          this.dialogService.open(ErrorListDialogComponent, {
            data: {
              title: error?.title,
              errors: error?.errors || [],
            },
          });
        });
      },
    });
  }

  addLine(goodsReceiptLine: GoodsReceiptLine) {
    this.goodsReceiptService.addReceiptLine(goodsReceiptLine, goodsReceiptLine.goodsReceipt.id).subscribe(() => {
      this.openSnackBar();

      this.isAdding = false;

      this.router.navigate([this.navigation]);
    });
  }

  openSnackBar() {
    this.snackBarService.open('Item updated succesfull.');
  }

  navigateBack() {
    this.location.back();
  }

  changeExpirationDate() {
    this.dialogService.open(ExpirationDialogComponent, {
      expirationDate: this.expirationDate,
    });
  }

  changeUom() {
    const dialog = this.dialogService.open(UomDialogComponent, {
      uom: this.scannedItem.unitOfMeasurements,
      chosenUom: this.chosenUom,
    });

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.chosenUom = data;

        this.exisitingGoodsReceiptLine = this.goodsReceipt.goodsReceiptLines.find((line) => {
          if (line.item.id === this.scannedItem.id && line.unitOfMeasurement.id === this.chosenUom.id) {
            this.quantityPicked = line.quantity;
            this.scannedItem = line.item;

            return true;
          }
        });
      }
    });
  }
}
