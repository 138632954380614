import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() position = 'end';
  @Input() closeOnBackButton = true;
  @ViewChild('sidenav', {
    static: true,
  })
  sidenav: MatSidenav;

  @ViewChild('MatSidenavContainer') sidenavContainer: MatSidenavContainer;

  constructor(private locationStrategy: LocationStrategy) {}

  toggle() {
    this.sidenav.toggle();

    // push dummy state
    if (this.sidenav.opened) {
      history.pushState(null, null, location.href);
    } else {
      // on-page interaction. pop back dummy state
      history.back();
    }
  }

  preventBackButton() {
    this.locationStrategy.onPopState(() => {
      // dummy state already popped
      if (this.sidenav.opened) {
        this.sidenav.close();
      } // close
    });
  }

  ngOnInit(): void {
    if (this.closeOnBackButton) {
      this.preventBackButton();
    }
  }
}
